<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
        >
            <template slot="left_btn">
                <a-button
                    class="float_left"
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                    >添加审批流程</a-button
                >
            </template>
            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider v-auth="'change'"  type="vertical" />
                <a v-auth="'change'" @click="change_status(data.record)">{{
                    data.record.status ? "禁用" : "启用"
                }}</a>
                <a-divider v-auth="'delete'" type="vertical" />
                <a-popconfirm
                    title="您确定要删除吗?"
                    v-auth="'delete'"
                    @confirm="del(data.record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getFlowList, removeFlow, changeStatus } from "@/api/approval";
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "审批流程名称",
        dataIndex: "name",
    },
    {
        title: "状态",
        dataIndex: "status_name",
    },
    {
        title: "应用表单",
        dataIndex: "approval",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: getFlowList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "审批流程名称",
                        placeholder: '输入审批流程名称',
                        options: {

                        }
                    },

                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
    },
    methods: {
        add() {
            this.$router.push("/approval/process_edit")
        },
        edit(data) {
            this.$router.push("/approval/process_edit?id=" + data.id)
        },

        change_status(data) {
            changeStatus({
                data: {
                    id: data.id,
                    status: data.status ? 0 : 1
                }
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        del(data) {
            removeFlow({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        add_user({ data }) {
            saveAuthUser({
                data: {
                    uid: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>